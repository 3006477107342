import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css'; // Import the CSS module
import logoImage from '../images/logo.png';
import softwareImage from './images/cpplus.jpg';
import miniProjImage from './images/dahua.jpg';
import internshipImage from './images/prama.jpg';
import managementImage from './images/hikvision.jpg'; // Import the management image
import ezvis from './images/ezviz.jpg'; // Import the management image
import TrueView from './images/trueview.png'; // Import the management image
import Godrej from './images/godrej.jpg'; // Import the management image
import Solar from './images/solar.jpg'; // Import the management image
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../../WhatsAppWidget';

const CCTV = () => {
    const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Close the menu when a link is clicked
  };


  const handleEnquiryClick1 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : HIKVISION CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick2 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : CP PLUS CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick3 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : PRAMA CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick4 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : Dahua CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick5 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : Ezviz CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick6 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : Trueview CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick7 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : Godrej CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick8 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Product Enquiry - Topic : Solar 4G, Wifi CCTV Cameras';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div>
      <Helmet>
        <title>CCTV Cameras</title>
        <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
        <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
        <meta property="og:title" content="CCTV Camera Sales in Pollachi" />
        <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
        <meta property="og:image" content={logoImage} />
        <meta property="og:url" content="https://dreamsofttechpollachi.com/systems/cctv-sales" />
        <link rel="canonical" href="https://dreamsofttechpollachi.com/systems/cctv-sales" />
      </Helmet>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logoImage} alt="Logo" />
        </div>
        <nav>
          <div className={styles.hamburger} onClick={toggleMenu}>
            {isOpen ? (
              <div className={styles.closeIcon}>X</div>
            ) : (
              <>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
              </>
            )}
          </div>
          <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
            <li>
              <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
            </li>
            <li>
              <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link>
            </li>
            <li>
              <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/cctv-sales' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
            </li>
            <li>
              <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
            </li>
            <li>
              <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.invisibleDiv}></div>
      </header>
      <div className={styles.headSection}>
        <h2 className={styles.topHeader}>CCTV CAMERAS</h2>
        <p className={styles.topParagraph}>Explore the CCTV camera brands that we offer in our store. We also provide services related to installation of CCTV cameras.</p>
      </div>
      <div className={styles.projectsSection}>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={managementImage} alt="Management" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>HIKVISION (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={softwareImage} alt="Software Development" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>CP PLUS (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={internshipImage} alt="Internship" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>PRAMA (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={miniProjImage} alt="Mini Projects" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>DAHUA (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={ezvis} alt="Mini Projects" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Ezviz (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick5}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={TrueView} alt="Mini Projects" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>True View (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick6}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={Godrej} alt="Mini Projects" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>GODREJ (Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick7}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectImage}>
              <img src={Solar} alt="Mini Projects" className={styles.managementImage} />
            </div>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Solar 4G, WIFI Cameras (Multi-Brand)</h3>
              <p className={styles.projectParagraph}>Category : CCTV Cameras</p>
              {/* <p className={styles.projPara2}>(Price Range)</p> */}
              <button className={styles.enquiryButton} onClick={handleEnquiryClick8}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
          </div>
        </div>
      </div>
      <WhatsAppWidget/>
      <footer className={styles.footer}>
        <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
      </footer>
    </div>
  );
}

export default CCTV;