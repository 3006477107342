import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import img1 from './images/panaadhar.png';
import img2 from './images/passport.jpg';
import img3 from './images/esevai.jpg';
import img4 from './images/type.jpg';
import img5 from './images/binding.jpg';
import img6 from './images/printing.jpg';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../WhatsAppWidget';

const EsevaiTN = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    const services = [
        { image: img3, text: "ஆன்லைன் சேவைகளைப் பார்க்க கிளிக் செய்யவும்", link: "/esevai/services/online-services-tamil" },
        { image: img1, text: "பான் கார்டு சேவைகளைப் பார்க்க கிளிக் செய்யவும்", link: "/esevai/services/pan-card-services-tamil" },
        { image: img2, text: "பாஸ்போர்ட் சேவையைப் பார்க்க கிளிக் செய்யவும்", link: "/esevai/services/passport-visa-services-tamil" },
        { image: img6, text: "பிரிண்ட் அவுட் மற்றும் லேமினேஷன் (upto A3) பார்க்க கிளிக் செய்யவும்", link: "/esevai/services/printout-services-tamil" },
        { image: img5, text: "பைண்டிங் ஒர்க்ஸ் பார்க்க கிளிக் செய்யவும்", link: "/esevai/services/binding-services-tamil" },
        { image: img4, text: "தட்டச்சு வேலைகளைக் காண கிளிக் செய்யவும் (தமிழ் மற்றும் ஆங்கிலம்)", link: "/esevai/services/typing-services-tamil" },
    ];

    return (
        <div>
            <Helmet>
                <title>இ-சேவை</title>
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>ஹோம்</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>இறுதி ஆண்டு திட்டங்கள்</Link>
                        </li>
                        <li>
                            <Link to="/esevai/tamil" className={`${styles.navLink} ${location.pathname === '/esevai/tamil' ? styles.active : ''}`} onClick={handleLinkClick}>இ-சேவை சேவைகள்</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>மல்டி பிராண்டு ஸ்டோர்</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>கேலரி</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>தொடர்பு</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>பொதுச் சேவைகளை மாற்றியமைத்தல்: எங்கள் இ-சேவைகளை பற்றி அறிந்து கொள்க</h2>
                <p className={styles.topParagraph}>ட்ரீம் சாஃப்ட் டெக் பெருமையுடன் பல்வேறு வகையான இ-சேவைகளை வழங்குகிறது, அரசு பரிவர்த்தனைகளை எளிதாக வழிநடத்துவதில் உங்கள் அனைத்து தேவைகளையும் பூர்த்தி செய்கிறது. எங்கள் விரிவான தீர்வுகள் உங்கள் ஆன்லைன் அனுபவத்தை மேம்படுத்த வடிவமைக்கப்பட்டுள்ளது, ஒவ்வொரு தொடர்பும் தடையற்றதாகவும் நேரடியானதாகவும் இருப்பதை உறுதிசெய்கிறது. டிரீம் சாஃப்ட் டெக்-ஐ நம்பி, செயல்திறன் மற்றும் வசதிக்கு முன்னுரிமை அளித்து, டிஜிட்டல் அரசாங்கச் சேவைகள் மூலம் உங்கள் பயணத்தை சிரமமில்லாமல் செய்யும் பயனர் நட்பு புதுமைகளை வழங்குங்கள்</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/english" className={`${styles.langtext} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>ஆங்கிலத்திற்கு மாற</Link>
            </div>
            <div className={styles.esevaiSection}>
                {services.map((service, index) => (
                    <div key={index} className={styles.container}>
                        <div className={styles.imageContainer}>
                            <img src={service.image} alt={service.text} className={styles.image} />
                        </div>
                        <div className={styles.textContainer}>
                            <Link to={service.link} className={styles.text}>{service.text}</Link>
                        </div>
                    </div>
                ))}
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default EsevaiTN;
