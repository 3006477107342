import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css';
import logoImage from '../images/logo.png';
import { Helmet } from 'react-helmet';
import EpsonImage from './images/epson.png';
import CanonImage from './images/canon.png';
import HpImage from './images/hpprinter.png';
import BrotherImage from './images/brother.png';
import TvsImage from './images/tvs.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import WhatsAppWidget from '../../WhatsAppWidget';

const PrinterServices = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };


    const handleEnquiryClick1 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Printer Sales Enquiry - Brand : EPSON';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick2 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Printer Sales Enquiry - Brand : CANON';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick3 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Printer Sales Enquiry - Brand : HP';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick4 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Printer Sales Enquiry - Brand : BROTHER';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick5 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Printer Sales Enquiry - Brand : TVS';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div>
            <Helmet>
                <title>Printers</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Printers Sales in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/systems/printer-sales" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/systems/printer-sales" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/printer-sales' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>PRINTER SALES</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide multi brand Laptops, Desktops with Customer's requirements. We have (LaserJet Printer, Inkjet Printer, Inktank, Thermal Printer, Label Printing and A3 Copier Printer)</p>
            </div>
            <div className={styles.projectsSection}>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={EpsonImage} alt="Management" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Epson</h3>
                            <p className={styles.projectParagraph}>Category : Printers</p>
                            <p className={styles.projPara2}></p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={CanonImage} alt="Software Development" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Canon</h3>
                            <p className={styles.projectParagraph}>Category : Printers</p>
                            {/* <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p> */}
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={HpImage} alt="Internship" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>HP</h3>
                            <p className={styles.projectParagraph}>Category : Printers</p>
                            {/* <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p> */}
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={BrotherImage} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Brother</h3>
                            <p className={styles.projectParagraph}>Category : Printers</p>
                            {/* <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p> */}
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={TvsImage} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>TVS</h3>
                            <p className={styles.projectParagraph}>Category : Printers</p>
                            {/* <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p> */}
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick5}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default PrinterServices;