import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import img1 from './images/panaadhar.png';
import img2 from './images/passport.jpg';
import img3 from './images/esevai.jpg';
import img4 from './images/type.jpg';
import img5 from './images/binding.jpg';
import img6 from './images/printing.jpg';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../WhatsAppWidget';

const EsevaiEN = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };

    const services = [
        { image: img3, text: "Click to view Online Services (E-Sevai)", link: "/esevai/services/online-services" },
        { image: img1, text: "Click to view PAN Card Services", link: "/esevai/services/pan-card-services" },
        { image: img2, text: "Click to view Passport Services", link: "/esevai/services/passport-visa-services" },
        { image: img6, text: "Click to view Xerox, Printing & Lamination (Upto A3)", link: "/esevai/services/printout-services" },
        { image: img5, text: "Click to view Binding Works", link: "/esevai/services/binding-services" },
        { image: img4, text: "Click to view Typing works (Tamil and English)", link: "/esevai/services/typing-services" },
    ];

    return (
        <div>
            <Helmet>
                <title>E-Sevai Services</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="E-Sevai Services in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/esevai/english" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/esevai/english" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>Transforming Public Services: Discover Our E-Sevai Services</h2>
                <p className={styles.topParagraph}>Dream Soft Tech proudly offers a diverse array of E-sevai services, catering to all your needs in navigating government transactions with ease. Our comprehensive range of solutions is crafted to enhance your online experience, ensuring every interaction is seamless and straightforward. Trust Dream Soft Tech to deliver user-friendly innovations that prioritize efficiency and convenience, making your journey through digital government services effortless.</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/tamil" className={`${styles.langtext} ${location.pathname === '/esevai/tamil' ? styles.active : ''}`} onClick={handleLinkClick}>Switch to தமிழ்</Link>
            </div>
            <div className={styles.esevaiSection}>
                {services.map((service, index) => (
                    <div key={index} className={styles.container}>
                        <div className={styles.imageContainer}>
                            <img src={service.image} alt={service.text} className={styles.image} />
                        </div>
                        <div className={styles.textContainer}>
                            <Link to={service.link} className={styles.text}>{service.text}</Link>
                        </div>
                    </div>
                ))}
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default EsevaiEN;
