import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Projects.module.css';
import logoImage from './images/logo.png';
import softwareImage from './images/softwaredev.png';
import miniProjImage from './images/miniprojects.png';
import internshipImage from './images/internship.png';
import managementImage from './images/management.png';
import thesisImage from './images/thesis.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../WhatsAppWidget';

const Projects = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleEnquiryClick1 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Project Enquiry - Topic : Management Projects';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick2 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Project Enquiry - Topic : Software Projects';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick3 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Project Enquiry - Topic : Internship Training Reports';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick4 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Project Enquiry - Topic : Mini Projects';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };
  const handleEnquiryClick5 = () => {
    const phoneNumber = '+918883039728';
    const message = 'Project Enquiry - Topic : Thesis Writing';
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappURL, '_blank');
  };

  return (
    <div>
      <Helmet>
        <title>Final Year Projects</title>
        <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
        <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
        <meta property="og:title" content="Best Project Center in Pollachi | Dream Soft Tech Pollachi" />
        <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
        <meta property="og:image" content={logoImage} />
        <meta property="og:url" content="https://dreamsofttechpollachi.com/projects/" />
        <link rel="canonical" href="https://dreamsofttechpollachi.com/projects/" />
      </Helmet>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logoImage} alt="Logo" />
        </div>
        <nav>
          <div className={styles.hamburger} onClick={toggleMenu}>
            {isOpen ? (
              <div className={styles.closeIcon}>X</div>
            ) : (
              <>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
              </>
            )}
          </div>
          <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
            <li>
              <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
            </li>
            <li>
              <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link>
            </li>
            <li>
              <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
            </li>
            <li>
              <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
            </li>
            <li>
              <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.invisibleDiv}></div>
      </header>
      <div className={styles.headSection}>
        <h2 className={styles.topHeader}>BEST PROJECT SOLUTIONS</h2>
        <p className={styles.topParagraph}>
          At our project center, we specialize in delivering top-tier management and software project services. Our commitment to excellence ensures success through meticulous planning, efficient resource allocation, and agile methodologies. We enhance productivity, collaboration, and the quality of software solutions. We also provide exceptional support for students, helping them achieve their project goals with confidence.</p>
      </div>
      <div className={styles.projectsSection}>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Management Projects</h3>
              <p className={styles.projectParagraph}> Management Projects can be done for the following categories</p>
              <p className={styles.projPara2}>Marketing | H.R | Finance | Export</p>
              <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
            <div className={styles.projectImage}>
              <img src={managementImage} alt="Management" className={styles.managementImage} />
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Software Projects</h3>
              <p className={styles.projectParagraph}>Software Projects can be done for the following Programming Languages</p>
              <p className={styles.projPara2}>Visual Basic | Vb.NET | Asp.NET | PHP | Android</p>
              <p className={styles.projPara3}>(Desktop Applications/Web Applications/Android Applications)</p>
              <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
            <div className={styles.projectImage}>
              <img src={softwareImage} alt="Software Development" className={styles.managementImage} />
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Internship Training Report</h3>
              <p className={styles.projectParagraph}>We provide internship training reports for the following streams</p>
              <p className={styles.projPara2}>Coir | Textile | Garments etc..</p>
              <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
            <div className={styles.projectImage}>
              <img src={internshipImage} alt="Internship" className={styles.managementImage} />
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>Mini Projects</h3>
              <p className={styles.projectParagraph}>We provide Mini Project Services based on Customer's requirements</p>
              <p className={styles.projPara2}>Eg: Desktop Applications | Web Applications etc..</p>
              <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
            <div className={styles.projectImage}>
              <img src={miniProjImage} alt="Mini Projects" className={styles.managementImage} />
            </div>
          </div>
        </div>
        <hr className={styles.line} />
        <div className={styles.projectContainer}>
          <div className={styles.projectContent}>
            <div className={styles.projectText}>
              <h3 className={styles.projectHeading}>M.Phil and Ph.D thesis</h3>
              <p className={styles.projectParagraph}>We provide Thesis writing services for (M.Phil and Ph.D) scholars</p>
              <button className={styles.enquiryButton} onClick={handleEnquiryClick5}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
            </div>
            <div className={styles.projectImage}>
              <img src={thesisImage} alt="Thesis Writing" className={styles.managementImage} />
            </div>
          </div>
        </div>
      </div>
      <WhatsAppWidget />
      <footer className={styles.footer}>
        <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
      </footer>
    </div>
  );
};

export default Projects;
