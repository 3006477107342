import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css';
import logoImage from '../images/logo.png';
import IntelLogo from './images/intel.png';
import AmdLogo from './images/amd.png';
import MotherBoardLogo from './images/motherboard.png';
import StorageLogo from './images/storage.png';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import WhatsAppWidget from '../../WhatsAppWidget';

const ComputerServices = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };


    const handleEnquiryClick1 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Topic : Intel Processors';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick2 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Topic : AMD Ryzen Processors';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick3 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Topic : Motherboards';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick4 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Topic : Storage Devices';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div>
            <Helmet>
                <title>Computer Components</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="PC Building & PC Components Sales in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/systems/computer-components" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/systems/computer-components" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/computer-components' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>COMPUTER COMPONENTS</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide multi brand Computer Hardware Components based on Customer's requirements. We also offer <b>PC Building Services</b>  to our customers.</p>
            </div>
            <div className={styles.projectsSection}>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={IntelLogo} alt="Management" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Intel</h3>
                            <p className={styles.projectParagraph}>Category : Laptops/Desktops (Processors)</p>
                            <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={AmdLogo} alt="Software Development" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>AMD</h3>
                            <p className={styles.projectParagraph}>Category : Laptops/Desktops (Processors)</p>
                            <p className={styles.projPara2}>Intel (Core i3/i5/i7) and AMD (Ryzen 3/5/7)</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={MotherBoardLogo} alt="Internship" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>MOTHERBOARDS</h3>
                            <p className={styles.projectParagraph}>Category : Desktops</p>
                            <p className={styles.projPara2}>LGA/AM4/AM5 Socket and (Multi Branded) eg: ASUS, GIGABYTE, etc..</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={StorageLogo} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Storage Devices</h3>
                            <p className={styles.projectParagraph}>Category : Laptops/Desktop/Mobiles</p>
                            <p className={styles.projPara2}>Hard Disk Drives/SSD Drives/NVME M.2 Drives/SAS Hard Drives/AV Hard Drives/Enterprise Hard Drives/External Hard Drives/Pen Drive/Memory Card</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsAppWidget />
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default ComputerServices;