import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import styles from './Home.module.css'; 
import logoImage from './logo.png'; 
import shopImage from './images/shop.jpg'; 
import mgImage from './images/projects.jpg'; 
import esevaImage from './images/esevai.jpg'; 
import sysImage from './images/multibrand.png'; 
import locationLogo from './location-icon.svg';
import whatsappLogo from './whatsapp-icon.svg';
import instaLogo from './email-icon.svg';
import callLogo from './call.svg';
import { useForm, ValidationError } from '@formspree/react';
import WhatsAppWidget from './WhatsAppWidget';

const Home = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false); 
  };
  const scrollToAbout = () => {
    const aboutSection = document.getElementById('about');
    aboutSection.scrollIntoView({ behavior: 'smooth' });
  };
  const [state, handleSubmit] = useForm("mwkgjlje");
  if (state.succeeded) {
    alert("Message sent successfully");
  }
  
  return (
    <div>
      <Helmet>
        <title>Dream Soft Tech Pollachi | Projects, E-Sevai, Computer Services & Accessories</title>
        <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
        <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
        <meta property="og:title" content="Dream Soft Tech Pollachi | Projects, E-Sevai, Computer Services & Accessories" />
        <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
        <meta property="og:image" content={logoImage} />
        <meta property="og:url" content="https://dreamsofttechpollachi.com" />
        <link rel="canonical" href="https://dreamsofttechpollachi.com" />
      </Helmet>
      <header className={styles.header}>
        <div className={styles.logo}>
          <img src={logoImage} alt="Logo" />
        </div>
        <nav>
          <div className={styles.hamburger} onClick={toggleMenu}>
            {isOpen ? (
              <div className={styles.closeIcon}>X</div>
            ) : (
              <>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
                <div className={styles.hamburgerLine}></div>
              </>
            )}
          </div>
          <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
            <li>
              <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
            </li>
            <li>
              <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
            </li>
            <li>
              <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link>
            </li>
            <li>
              <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
            </li>
            <li>
              <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
            </li>
            <li>
              <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
            </li>
          </ul>
        </nav>
        <div className={styles.invisibleDiv}></div> {/* Invisible div */}
      </header>
      <div className={styles.mainContent}>
        <div className={styles.contentWrapper}>
          <div className={styles.leftContent}>
            <h1 className={styles.heading}>
              <span className={styles.gradientText}>
                "Empowering Tomorrow, Today: Your Gateway to Tech Excellence"
              </span>
            </h1>
            <p className={styles.paragraph}>
            Welcome to Dream Soft Tech, your destination for innovative technology and excellence. We offer a wide range of services, from advanced student projects to efficient E-Sevai services, all designed to enhance your convenience. Whether you need printing, binding, or tech upgrades, our diverse offerings have you covered. At Dream Soft Tech, we turn dreams into reality. Join us on the path to success!
            </p>
            <button onClick={scrollToAbout} className={styles.moreButton}>
              More about us
            </button>
          </div>
          <div className={styles.rightContent}>
            <img src={shopImage} alt="Shop" className={styles.shopImage} />
          </div>
        </div>
      </div>
      <div className={styles.servicesSection}>
        <h2 className={styles.servicesHeading}>OUR SERVICES</h2>
        <p className={styles.servicesParagraph}>Explore Our Wide Range of Professional Services</p>
        <div className={styles.servicesContainer}>
          <div className={styles.serviceBox}>
            <img src={mgImage} alt="Management" className={styles.serviceImage} />
            <div className={styles.serviceContent}>
              <Link to='/projects/' className={styles.serviceHeading}>Final Year (UG and PG) Projects</Link>
              <p className={styles.serviceText}>Find creative solutions for your projects in management and software development. We also offer <strong>UG and PG final year projects</strong> for students and <strong>M. Phil, Ph.D Thesis work.</strong></p>
            </div>
          </div>
          <div className={styles.serviceBox}>
            <img src={esevaImage} alt="E-Sevai" className={styles.serviceImage} />
            <div className={styles.serviceContent}>
              <Link to='/esevai/english' className={styles.serviceHeading}>TN E-Sevai Services (CSC)</Link>
              <p className={styles.serviceText}>Access government services hassle-free through our authorized e-Sevai centre. From Aadhar to passport services, and more.</p>
            </div>
          </div>
          <div className={styles.serviceBox}>
            <img src={sysImage} alt="Systems" className={styles.serviceImage} />
            <div className={styles.serviceContent}>
              <Link to='/systems/system-services' className={styles.serviceHeading}>Multi Branded Computer, Laptop Store</Link>
              <p className={styles.serviceText}>Explore a variety of tech solutions with us. We offer laptops, computers, printers, and CCTV systems for all your needs.</p>
            </div>
          </div>
        </div>
      </div>
      <div id="about" className={styles.aboutSection}>
        <h2 className={styles.aboutHeading}>ABOUT US</h2>
        <p className={styles.aboutParagraph}>
          At Dream Soft Tech, we're more than just a store – we're your tech partner. Since our establishment in 2014, we've been dedicated to providing exceptional service and solutions to our community in Pollachi and beyond. Located on Pollachi-Palakkad Main Road, we've become a trusted destination for students seeking project guidance, individuals in need of printing services, and tech enthusiasts searching for the latest gadgets. Our experienced team is committed to delivering personalized assistance and ensuring your satisfaction with every visit. Come visit us today and experience the Dream Soft Tech difference!
        </p>
      </div>
      <WhatsAppWidget />
      <footer className={styles.footer}>
        <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
      </footer>
    </div>
  );
};

export default Home;
