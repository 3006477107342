import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css';
import logoImage from '../images/logo.png';
import UpsLogo from './images/ups.png';
import CabinetLogo from './images/cabinet.jpg';
import CoolerMasterLogo from './images/coolermaster.jpg';
import CoolerLogo from './images/cooler.jpg';
import KeyboardLogo from './images/keyboard.jpg';
import MouseLogo from './images/mouse.jpg';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import WhatsAppWidget from '../../WhatsAppWidget';
// import { keyboard } from '@testing-library/user-event/dist/keyboard';

const ComputerAccessories = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };


    const handleEnquiryClick1 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Computer UPS';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick2 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - PC Cabinets';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick3 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - SMPS';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick4 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Cooler Fans';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick5 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Keyboards';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick6 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Enquiry - Mouse';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div>
            <Helmet>
                <title>Computer Accessories</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Computer Accessories in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/systems/computer-accessories" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/systems/computer-accessories" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/computer-accessories' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>COMPUTER ACCESSORIES</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide multi brand Computer Accessories based on Customer's requirements.</p>
            </div>
            <div className={styles.projectsSection}>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={UpsLogo} alt="Management" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>UPS</h3>
                            <p className={styles.projectParagraph}>Category : Desktops</p>
                            <p className={styles.projPara2}>Fingers, Numeric Brand UPS Available</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={CabinetLogo} alt="Software Development" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Cabinets</h3>
                            <p className={styles.projectParagraph}>Category : Desktops</p>
                            <p className={styles.projPara2}>Multi branded Cabinets & RGB/Non RGB</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={CoolerMasterLogo} alt="Internship" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>SMPS/Power Supply</h3>
                            <p className={styles.projectParagraph}>Category : Desktops</p>
                            <p className={styles.projPara2}>Multi Branded SMPS Available</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={CoolerLogo} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Cooler Fan</h3>
                            <p className={styles.projectParagraph}>Category : Desktops</p>
                            <p className={styles.projPara2}>RGB and ARGB Cooler Fans</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={KeyboardLogo} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>KEYBOARD</h3>
                            <p className={styles.projectParagraph}>Category : Laptops/Desktops</p>
                            <p className={styles.projPara2}>Normal/Gaming Keyboards (Wired and Wireless)</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick5}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={MouseLogo} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>MOUSE</h3>
                            <p className={styles.projectParagraph}>Category : Laptops/Desktops</p>
                            <p className={styles.projPara2}>Normal/Gaming Mouse (Wired and Wireless)</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick6}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Send your Requirements</button>
                        </div>
                    </div>
                </div>
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default ComputerAccessories;