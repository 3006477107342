import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../../WhatsAppWidget';

const Others = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };
    return (
        <div>
            <Helmet>
                <title>Online Services</title>
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/services/online-services' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>Online Services</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide the following Online services</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/services/online-services-tamil" className={`${styles.langtext} ${location.pathname === '/esevai/services/online-services-tamil' ? styles.active : ''}`} onClick={handleLinkClick}>Switch to தமிழ்</Link>
            </div>
            <div className={styles.bodySection}>
                <hr className={styles.line} />
                <p className={styles.list}>1. Community Certificate</p><hr className={styles.line} />
                <p className={styles.list}>2. Income Certificate</p><hr className={styles.line} />
                <p className={styles.list}>3. Nativity Certificate</p><hr className={styles.line} />
                <p className={styles.list}>4. First Graduate Certificate</p><hr className={styles.line} />
                <p className={styles.list}>5. Voter ID - Apply New and Correction</p><hr className={styles.line} />
                <p className={styles.list}>6. TNPSC Exam Apply</p><hr className={styles.line} />
                <p className={styles.list}>7. Bank Exam Apply</p><hr className={styles.line} />
                <p className={styles.list}>8. RRB Exam Apply</p><hr className={styles.line} />
                <p className={styles.list}>9. Apply for all Government and Private Job</p><hr className={styles.line} />
                <p className={styles.list}>10. Employment Registration (New, Renewal & Update)</p><hr className={styles.line} />
                <p className={styles.list}>11. EB Bill Payment</p><hr className={styles.line} />
                <p className={styles.list}>12. All Mobile Recharge</p><hr className={styles.line} />
                <p className={styles.list}>13. Smart Card Apply - (New Card Apply, Card Reprint apply, Members add and remove)</p><hr className={styles.line} />
                <p className={styles.list}>14. Smart Card Update - (Members - Add/Remove/Family Head Change)</p><hr className={styles.line} />
                <p className={styles.list}>15. Fast tag Recharge</p><hr className={styles.line} />
                <p className={styles.list}>16. Traffic Fine Payment</p><hr className={styles.line} />
                <p className={styles.list}>17. Police Verification Certificate</p><hr className={styles.line} />
                <p className={styles.list}>18. Money Transfer (Deposit & Withdrawl (AEPS))</p><hr className={styles.line} />
                <p className={styles.list}>19. UDAYAM Registration (MSME Certificate)</p><hr className={styles.line} />
                <p className={styles.list}>20. Ticket Booking (Bus,Train,Flight)</p><hr className={styles.line} />
                <p className={styles.list}>21. Online EC Print</p><hr className={styles.line} />
                <p className={styles.list}>22. Patta/Chitta</p><hr className={styles.line} />
                {/* <p className={styles.list}>22. SSI - Udayam</p><hr className={styles.line}/> */}
                <p className={styles.list}>23. Two Wheeler and Four Wheeler Insurance</p><hr className={styles.line} />
                <p className={styles.list}>24. Sabarimala dharisanam Ticket booking</p><hr className={styles.line} />
                <p className={styles.list}>25. E-Pass Apply</p><hr className={styles.line} />
                <p className={styles.list}>26. E-Way Bill</p><hr className={styles.line} />
                <p className={styles.list}>27. Horoscope Print - One Side</p><hr className={styles.line} />
                <p className={styles.list}>28. Baby name list - Numerology</p><hr className={styles.line} />
                {/* <p className={styles.list}>29. Marriage matching</p><hr className={styles.line}/> */}
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default Others;