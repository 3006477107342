import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Gallery.module.css';
import logoImage from '../logo.png';
import photo1 from './images/image1.jpg'; // Update with actual paths
import photo2 from './images/image2.jpg';
import photo3 from './images/image28.jpg';
import photo4 from './images/image36.jpg';
import photo5 from './images/image17.jpg';
import photo6 from './images/image37.jpg';
import photo7 from './images/image13.jpg';
import photo8 from './images/image38.jpg';
import photo9 from './images/image39.jpg';
import photo10 from './images/image40.jpg';
import photo11 from './images/image41.jpg';
import photo12 from './images/image42.jpg';
import photo13 from './images/image43.jpg';
import photo14 from './images/image44.jpg';
import photo15 from './images/image45.jpg';
import photo16 from './images/image46.jpg';
import photo17 from './images/image47.jpg';
import photo18 from './images/image48.jpg';
import photo19 from './images/image49.jpg';
import photo20 from './images/image50.jpg';
import photo21 from './images/image51.jpg';
import photo22 from './images/image52.jpg';
import photo23 from './images/image53.jpg';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../WhatsAppWidget';

const Gallery = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };
    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const closePopup = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            <Helmet>
                <title>Our Gallery</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Our Gallery" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/gallery" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/gallery" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>OUR GALLERY</h2>
                <p className={styles.topParagraph}>Explore our Gallery</p>
            </div>
            <div className={styles.galleryGrid}>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo1)}>
                    <img src={photo1} alt="Gallery Item 1" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo2)}>
                    <img src={photo2} alt="Gallery Item 2" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo3)}>
                    <img src={photo3} alt="Gallery Item 3" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo4)}>
                    <img src={photo4} alt="Gallery Item 4" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo5)}>
                    <img src={photo5} alt="Gallery Item 5" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo6)}>
                    <img src={photo6} alt="Gallery Item 6" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo7)}>
                    <img src={photo7} alt="Gallery Item 7" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo8)}>
                    <img src={photo8} alt="Gallery Item 8" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo9)}>
                    <img src={photo9} alt="Gallery Item 9" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo10)}>
                    <img src={photo10} alt="Gallery Item 10" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo11)}>
                    <img src={photo11} alt="Gallery Item 11" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo12)}>
                    <img src={photo12} alt="Gallery Item 12" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo13)}>
                    <img src={photo13} alt="Gallery Item 13" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo14)}>
                    <img src={photo14} alt="Gallery Item 14" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo15)}>
                    <img src={photo15} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo16)}>
                    <img src={photo16} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo17)}>
                    <img src={photo17} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo18)}>
                    <img src={photo18} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo19)}>
                    <img src={photo19} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo20)}>
                    <img src={photo20} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo21)}>
                    <img src={photo21} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo22)}>
                    <img src={photo22} alt="Gallery Item 15" />
                </div>
                <div className={styles.galleryItem} onClick={() => handleImageClick(photo23)}>
                    <img src={photo23} alt="Gallery Item 15" />
                </div>
                {/* Add more images as needed */}
            </div>
            {selectedImage && (
                <div className={styles.popup} onClick={closePopup}>
                    <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                        <span className={styles.closePopup} onClick={closePopup}>&times;</span>
                        <img src={selectedImage} alt="Selected" className={styles.popupImage} />
                    </div>
                </div>
            )}
            <WhatsAppWidget />
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default Gallery;
