import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../../WhatsAppWidget';

const OthersTN = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };
    return (
        <div>
            <Helmet>
                <title>ஆன்லைன் சேவைகள்</title>
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>ஹோம்</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>இறுதி ஆண்டு திட்டங்கள்</Link>
                        </li>
                        <li>
                            <Link to="/esevai/tamil" className={`${styles.navLink} ${location.pathname === '/esevai/services/online-services-tamil' ? styles.active : ''}`} onClick={handleLinkClick}>இ-சேவை சேவைகள்</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>மல்டி பிராண்டு ஸ்டோர்</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>கேலரி</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>தொடர்பு</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>ஆன்லைன் சேவைகள்</h2>
                <p className={styles.topParagraph}>Dream Soft Tech இல், பின்வரும் ஆன்லைன் சேவைகளை நாங்கள் வழங்குகிறோம்</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/services/online-services" className={`${styles.langtext} ${location.pathname === '/esevai/services/online-services' ? styles.active : ''}`} onClick={handleLinkClick}>ஆங்கிலத்திற்கு மாற</Link>
            </div>
            <div className={styles.bodySection}>
                <hr className={styles.line} />
                <p className={styles.list}>1. சாதிச் சான்றிதழ்</p><hr className={styles.line} />
                <p className={styles.list}>2. வருமானச் சான்றிதழ்</p><hr className={styles.line} />
                <p className={styles.list}>3. நேட்டிவிட்டி சான்றிதழ்</p><hr className={styles.line} />
                <p className={styles.list}>4. முதல் பட்டதாரி சான்றிதழ்</p><hr className={styles.line} />
                <p className={styles.list}>5. வாக்காளர் அட்டை - புதிய மற்றும் திருத்தத்திற்கு விண்ணப்பிக்கவும்</p><hr className={styles.line} />
                <p className={styles.list}>6. TNPSC தேர்வு விண்ணப்பம்</p><hr className={styles.line} />
                <p className={styles.list}>7. வங்கி தேர்வு விண்ணப்பம்</p><hr className={styles.line} />
                <p className={styles.list}>8. RRB தேர்வு விண்ணப்பம்</p><hr className={styles.line} />
                <p className={styles.list}>9. அனைத்து அரசு மற்றும் தனியார் வேலைகளுக்கும் விண்ணப்பிக்கவும்</p><hr className={styles.line} />
                <p className={styles.list}>10. வேலைவாய்ப்பு பதிவு (புதிய, புதுப்பித்தல்)</p><hr className={styles.line} />
                <p className={styles.list}>11. EB பில் செலுத்துதல்</p><hr className={styles.line} />
                <p className={styles.list}>12. அனைத்து மொபைல் ரீசார்ஜ்</p><hr className={styles.line} />
                <p className={styles.list}>13. ஸ்மார்ட் கார்டு விண்ணப்பிக்கவும் - (புதிய கார்டு விண்ணப்பிக்கவும், கார்டு மறுபதிப்பு விண்ணப்பிக்கவும், உறுப்பினர்கள் சேர்த்தல் மற்றும் நீக்குதல்)</p><hr className={styles.line} />
                <p className={styles.list}>14. ஸ்மார்ட் கார்டு விண்ணப்பிக்கவும் - (புதிய கார்டு விண்ணப்பிக்கவும், கார்டு மறுபதிப்பு விண்ணப்பிக்கவும், உறுப்பினர்கள் சேர்த்தல் மற்றும் நீக்குதல்)</p><hr className={styles.line} />
                <p className={styles.list}>15. ஃபாஸ்ட் டேக் ரீசார்ஜ்</p><hr className={styles.line} />
                <p className={styles.list}>16. போக்குவரத்து அபராதம் செலுத்துதல்</p><hr className={styles.line} />
                <p className={styles.list}>17. போலீஸ் சரிபார்ப்பு சான்றிதழ்</p><hr className={styles.line} />
                <p className={styles.list}>18. பணப் பரிமாற்றம் (டெபாசிட் & திரும்பப் பெறுதல்)</p><hr className={styles.line} />
                <p className={styles.list}>19. உதயம் பதிவு (MSME சான்றிதழ்)</p><hr className={styles.line} />
                <p className={styles.list}>20. டிக்கெட் முன்பதிவு (பஸ், ரயில், விமானம்)</p><hr className={styles.line} />
                <p className={styles.list}>21. ஆன்லைன் EC அச்சு</p><hr className={styles.line} />
                <p className={styles.list}>22. பட்டா/சிட்டா</p><hr className={styles.line} />
                <p className={styles.list}>23. இரு சக்கர வாகனம் மற்றும் நான்கு சக்கர வாகன காப்பீடு</p><hr className={styles.line} />
                <p className={styles.list}>24. சபரிமலை தரிசன டிக்கெட் முன்பதிவு</p><hr className={styles.line} />
                <p className={styles.list}>25. இ-பாஸ் விண்ணப்பிக்கவும்</p><hr className={styles.line} />
                <p className={styles.list}>26. இ-வே பில்</p><hr className={styles.line} />
                <p className={styles.list}>27. ஜாதக அச்சு - ஒரு பக்கம்</p><hr className={styles.line} />
                <p className={styles.list}>28. குழந்தை பெயர் பட்டியல்</p><hr className={styles.line} />
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default OthersTN;