import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../../WhatsAppWidget';

const Binding = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };
    return (
        <div>
            <Helmet>
                <title>Binding Services</title>
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/services/binding-services' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>Binding Services</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide the following Binding services</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/services/binding-services-tamil" className={`${styles.langtext} ${location.pathname === '/esevai/services/binding-services-tamil' ? styles.active : ''}`} onClick={handleLinkClick}>Switch to தமிழ்</Link>
            </div>
            <div className={styles.bodySection}>
                <hr className={styles.line} />
                <p className={styles.list}>1. Spiral Binding</p><hr className={styles.line} />
                <p className={styles.list}>2. Soft Binding</p><hr className={styles.line} />
                <p className={styles.list}>3. Hard Binding</p><hr className={styles.line} />
                <p className={styles.list}>4. Calico Binding</p><hr className={styles.line} />
                <p className={styles.list}>5. Wrapper Book Binding</p><hr className={styles.line} />
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default Binding;