import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css';
import logoImage from '../images/logo.png';
import { Helmet } from 'react-helmet';
import EpsonImage from './images/laptopservice.jpg';
import CanonImage from './images/deskservice.jpg';
import HpImage from './images/printser.jpg';
import BrotherImage from './images/datarec.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import WhatsAppWidget from '../../WhatsAppWidget';

const OtherServices = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };


    const handleEnquiryClick1 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Service Enquiry : Laptop';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick2 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Service Enquiry : Desktop';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick3 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Service Enquiry : Printer';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };
    const handleEnquiryClick4 = () => {
        const phoneNumber = '+918883039728';
        const message = 'Product Service Enquiry : Data Recovery';
        const encodedMessage = encodeURIComponent(message);
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
        window.open(whatsappURL, '_blank');
    };

    return (
        <div>
            <Helmet>
                <title>Services</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Printers Sales in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com/systems/device-service" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com/systems/device-service" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/device-service' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>SERVICES</h2>
                <p className={styles.topParagraph}>In Dream Soft Tech, We provide Services for Laptops/Desktops/Printers.</p>
            </div>
            <div className={styles.projectsSection}>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={EpsonImage} alt="Management" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Laptop Services</h3>
                            <p className={styles.projectParagraph}><b>Includes :</b>  No Power ON | No Display |  Blue Screen Error | Hinge Problems | Panel Broken Problem | Panel broken Rework | Keyboard Replacement | Battery Replacement | Panel Replacement for available Models | Screen Replacement | LED, LCD, IPS Screen Replacement</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick1}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={CanonImage} alt="Software Development" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Desktop Services</h3>
                            <p className={styles.projectParagraph}><b>Includes :</b> No power on | Hanging | Blue screen error | Restarting | OS Crash | PCB/Track damage | Overheat | No Display | Bios not saving | Audio problem</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick2}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={HpImage} alt="Internship" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Printer Services</h3>
                            <p className={styles.projectParagraph}><b>Includes :</b> Toner Refilling | No Power ON | Printout Line Problem | Paper Jam | USB Not Detect | Recondition Toner Cartridge | Drum/Blade/PCR Roller/Magnetic Roller Change | Board Service | Ink Refilling | Waste Ink Pad Change | General Service</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick3}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
                        </div>
                    </div>
                </div>
                <hr className={styles.line} />
                <div className={styles.projectContainer}>
                    <div className={styles.projectContent}>
                        <div className={styles.projectImage}>
                            <img src={BrotherImage} alt="Mini Projects" className={styles.managementImage} />
                        </div>
                        <div className={styles.projectText}>
                            <h3 className={styles.projectHeading}>Data Recovery</h3>
                            <p className={styles.projectParagraph}><b>Includes :</b> Laptop/Desktop HDD | External HDD | SSD HDD | Pendrive | All Media Cards | Network-Attached Storage (NAS)</p>
                            <button className={styles.enquiryButton} onClick={handleEnquiryClick4}><FontAwesomeIcon icon={faWhatsapp} className={styles.whatsappIcon} />Enquiry</button>
                        </div>
                    </div>
                </div>

            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default OtherServices;