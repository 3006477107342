import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './Esevai.module.css';
import logoImage from '../logo.png';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../../WhatsAppWidget';

const BindingTN = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false); // Close the menu when a link is clicked
    };
    return (
        <div>
            <Helmet>
                <title>பிணைப்பு சேவைகள்</title>
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>ஹோம்</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>இறுதி ஆண்டு திட்டங்கள்</Link>
                        </li>
                        <li>
                            <Link to="/esevai/tamil" className={`${styles.navLink} ${location.pathname === '/esevai/services/binding-services-tamil' ? styles.active : ''}`} onClick={handleLinkClick}>இ-சேவை சேவைகள்</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>மல்டி பிராண்டு ஸ்டோர்</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>கேலரி</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>தொடர்பு</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <hr className={styles.line} />
                <h2 className={styles.topHeader}>பிணைப்பு சேவைகள்</h2>
                <p className={styles.topParagraph}>Dream Soft Tech இல், பின்வரும் பிணைப்பு சேவைகளை நாங்கள் வழங்குகிறோம்</p>
            </div>
            <div className={styles.lang}>
                <Link to="/esevai/services/binding-services" className={`${styles.langtext} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>ஆங்கிலத்திற்கு மாற</Link>
            </div>
            <div className={styles.bodySection}>
                <hr className={styles.line} />
                <p className={styles.list}>1. ஸ்பைரல் பைண்டிங்</p><hr className={styles.line} />
                <p className={styles.list}>2. சாப்ட் பைண்டிங்</p><hr className={styles.line} />
                <p className={styles.list}>3. ஹார்டு பைண்டிங்</p><hr className={styles.line} />
                <p className={styles.list}>4. காலிகோ பைண்டிங்</p><hr className={styles.line} />
                <p className={styles.list}>5. ராப்பர் புத்தகம் பைண்டிங்</p><hr className={styles.line} />
            </div>
            <WhatsAppWidget/>
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>
    );
}

export default BindingTN;