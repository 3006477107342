import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Projects from './components/Projects/Main';
import ESevaiEN from './components/ESevai/Esevai_en';
import ESevaiTAM from './components/ESevai/Esevai_tn';
import PanCard from './components/ESevai/services/pancard';
import Binding from './components/ESevai/services/binding';
import Online from './components/ESevai/services/others';
import Printing from './components/ESevai/services/printing';
import Passport from './components/ESevai/services/passport';
import Typing from './components/ESevai/services/typing';
import System from './components/Systems/System';
import PanCardTN from './components/ESevai/services/pancard_tn';
import PassportTN from './components/ESevai/services/passport_tn';
import OthersTN from './components/ESevai/services/others_tn';
import TypingTN from './components/ESevai/services/typing_tn';
import BindingTN from './components/ESevai/services/binding_tn';
import PrintingTN from './components/ESevai/services/printing_tn';
import CCTV from './components/Systems/Services/cctv';
import ComputerAccessories from './components/Systems/Services/computeraccessories';
import ComputerComponents from './components/Systems/Services/computercomponents';
import LaptopDesktopSales from './components/Systems/Services/laptopsales';
// import LaptopServices from './components/Systems/Services/lapservice';
// import MobileAccessories from './components/Systems/Services/mobileacc';
import OtherServices from './components/Systems/Services/services';
// import PrinterAccessories from './components/Systems/Services/printacc';
import PrinterServices from './components/Systems/Services/printservice';
import Gallery from './components/gallery/Gallery';
import Contact from './components/Contact';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} />
        <Route path="/projects/" element={<Projects />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/esevai/english" element={<ESevaiEN />} />
        <Route path="/esevai/tamil" element={<ESevaiTAM />} />
        <Route path="/esevai/services/pan-card-services" element={<PanCard />} />
        <Route path="/esevai/services/pan-card-services-tamil" element={<PanCardTN />} />
        <Route path="/esevai/services/passport-visa-services" element={<Passport />} />
        <Route path="/esevai/services/passport-visa-services-tamil" element={<PassportTN />} />
        <Route path="/esevai/services/online-services" element={<Online />} />
        <Route path="/esevai/services/online-services-tamil" element={<OthersTN />} />
        <Route path="/esevai/services/typing-services" element={<Typing />} />
        <Route path="/esevai/services/typing-services-tamil" element={<TypingTN />} />
        <Route path="/esevai/services/binding-services" element={<Binding />} />
        <Route path="/esevai/services/binding-services-tamil" element={<BindingTN />} />
        <Route path="/esevai/services/printout-services" element={<Printing />} />
        <Route path="/esevai/services/printout-services-tamil" element={<PrintingTN />} />
        <Route path="/systems/system-services" element={<System />} />
        <Route path="/systems/cctv-sales" element={<CCTV />} />
        <Route path="/systems/computer-accessories" element={<ComputerAccessories />} />
        <Route path="/systems/computer-components" element={<ComputerComponents />} />
        <Route path="/systems/laptopdesktop-sales" element={<LaptopDesktopSales />} />
        {/* <Route path="/systems/laptop-services" element={<LaptopServices />} /> */}
        {/* <Route path="/systems/mobile-accessories" element={<MobileAccessories />} /> */}
        <Route path="/systems/device-service" element={<OtherServices />} />
        <Route path="/systems/printer-sales" element={<PrinterServices />} />
        {/* <Route path="/systems/printer-accessories" element={<PrinterAccessories />} /> */}
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
