import React from 'react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './System.module.css';
import logoImage from './images/logo.png';
import img1 from './images/cctv.png';
import img2 from './images/accessories.png';
import img3 from './images/desktopservice.png';
import img4 from './images/lapseales.png';
import img5 from './images/epson.jpg';
import img6 from './images/otherservice.png';
import { Helmet } from 'react-helmet';
import WhatsAppWidget from '../WhatsAppWidget';

const System = () => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const handleLinkClick = () => {
        setIsOpen(false);
    };

    const services = [
        { image: img4, text: "Click to view (Laptop and Desktop) Sales", link: "/systems/laptopdesktop-sales" },
        { image: img5, text: "Click to view Printer Sales", link: "/systems/printer-sales" },
        { image: img1, text: "Click to view CCTV Cameras", link: "/systems/cctv-sales" },
        { image: img2, text: "Click to view Computer Accessories", link: "/systems/computer-accessories" },
        { image: img3, text: "Click to view Computer Components", link: "/systems/computer-components" },
        { image: img6, text: "Click to view Desktop/Laptop/Printer Services", link: "/systems/device-service" },
    ];

    return (
        <div>
            <Helmet>
                <title>Multi Brand Computer Store</title>
                <meta name="description" content="Welcome to Dream Soft Tech, your tech destination for innovation and excellence in Pollachi. Explore our services including student projects, e-Sevai solutions, and tech upgrades." />
                <meta name="keywords" content="best project centre in pollachi, e-sevai services in pollachi, dream soft tech pollachi, best system services in pollachi, dream soft tech" />
                <meta property="og:title" content="Laptop Sales, Desktop Sales in Pollachi" />
                <meta property="og:description" content="Dream Soft Tech Pollachi: Best project center and E-Sevai services in Pollachi. Offering multibranded laptops, desktops, CCTV, and printers. Expert services for laptops, desktops, and printers by certified technicians." />
                <meta property="og:image" content={logoImage} />
                <meta property="og:url" content="https://dreamsofttechpollachi.com" />
                <link rel="canonical" href="https://dreamsofttechpollachi.com" />
            </Helmet>
            <header className={styles.header}>
                <div className={styles.logo}>
                    <img src={logoImage} alt="Logo" />
                </div>
                <nav>
                    <div className={styles.hamburger} onClick={toggleMenu}>
                        {isOpen ? (
                            <div className={styles.closeIcon}>X</div>
                        ) : (
                            <>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                                <div className={styles.hamburgerLine}></div>
                            </>
                        )}
                    </div>
                    <ul className={`${styles.navLinks} ${isOpen ? styles.active : ''}`}>
                        <li>
                            <Link to="/" className={`${styles.navLink} ${location.pathname === '/' ? styles.active : ''}`} onClick={handleLinkClick}>Home</Link>
                        </li>
                        <li>
                            <Link to="/projects/" className={`${styles.navLink} ${location.pathname === '/projects/' ? styles.active : ''}`} onClick={handleLinkClick}>Final Year Projects</Link>
                        </li>
                        <li>
                            <Link to="/esevai/english" className={`${styles.navLink} ${location.pathname === '/esevai/english' ? styles.active : ''}`} onClick={handleLinkClick}>E-Sevai Services</Link></li>
                        <li>
                            <Link to="/systems/system-services" className={`${styles.navLink} ${location.pathname === '/systems/system-services' ? styles.active : ''}`} onClick={handleLinkClick}>Our Multi Branded Store</Link>
                        </li>
                        <li>
                            <Link to="/gallery" className={`${styles.navLink} ${location.pathname === '/gallery' ? styles.active : ''}`} onClick={handleLinkClick}>Gallery</Link>
                        </li>
                        <li>
                            <Link to="/contact" className={`${styles.navLink} ${location.pathname === '/contact' ? styles.active : ''}`} onClick={handleLinkClick}>Contact</Link>
                        </li>
                    </ul>
                </nav>
                <div className={styles.invisibleDiv}></div>
            </header>
            <div className={styles.headSection}>
                <h2 className={styles.topHeader}>Comprehensive System Services and Tech Accessories Sales</h2>
                <p className={styles.topParagraph}>At Dream Soft Tech, we offer high-quality system services to keep your tech devices running smoothly. Additionally, we sell a wide range of tech gadgets, providing everything you need in one place. Our mission is to deliver top-notch services and products to help you stay connected and up-to-date with the latest technology.</p>
            </div>
            <div className={styles.systemSection}>
                {services.map((service, index) => (
                    <div key={index} className={styles.container}>
                        <div className={styles.imageContainer}>
                            <img src={service.image} alt={service.text} className={styles.image} />
                        </div>
                        <div className={styles.textContainer}>
                            <Link to={service.link} className={styles.text}>{service.text}</Link>
                        </div>
                    </div>
                ))}
            </div>
            <WhatsAppWidget />
            <footer className={styles.footer}>
                <p className={styles.footerText}>© 2024 Dream Soft Tech - Pollachi</p>
            </footer>
        </div>);
}

export default System;
